:root {
  --baseUI: rgb(28, 39, 52);
  --baseUIBorder: rgb(20, 52, 61);
  --baseUIDark: rgb(23, 31, 42);
  --baseText: rgb(255, 234, 228);
  --specialHighlight: linear-gradient(180deg, #ff8d6d 0%, #ffffff 100%);

  --baseFont: "Alegreya";
}

.app {
  height: 90vh;
  width: 100%;
  padding-top: 10vh;
}

#app-content-container {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 2fr 3rem;
  row-gap: 24px;
  width: 100%;
  height: 100%;
  padding: 0;
  font-family: var(--baseFont);
}

#app-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: radial-gradient(
    155.03% 155.03% at 50% 33.45%,
    #000000 0.22%,
    #050505 35.77%,
    #ff8d6d 100%
  );
  background-size: 110%;
  animation: flicker 10s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.app > canvas {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

@keyframes flicker {
  0%,
  75% {
    background-size: 110% 110%;
  }
  50%,
  100% {
    background-size: 105% 105%;
  }
  25% {
    background-size: 100% 100%;
  }
}

.App:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  animation: flickerOverlay 0.2s linear infinite;
}

@keyframes flickerOverlay {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
}

#main-logo {
  max-height: 40vh;
  max-width: 60%;
  aspect-ratio: 1;
  margin: 0 auto;
  align-self: center;
}

#text-container {
  margin: 32px auto;
  width: 60%;
}

#text-header {
  margin: 0px;
  color: var(--baseText);
  font-size: 4rem;
  text-align: center;
}

#text-content {
  margin: 16px 0px 0px;
  color: var(--baseText);
  font-size: 2rem;
  text-align: center;
}

#footer-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

#footer-contact,
#footer-contact-email {
  color: var(--baseText);
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
}

@media (max-width: 1024px) {
  #text-header {
    font-size: 3rem;
  }

  #text-content {
    font-size: 1.6rem;
  }

  #footer-contact,
  #footer-contact-email {
    font-size: 1.5rem;
  }

  #main-logo {
    width: 30%;
  }

  #text-container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .app {
    height: 95vh;
    padding-top: 5vh;
  }

  #text-container {
    margin: 8px auto;
  }

  #text-header {
    font-size: 2.5rem;
  }

  #text-content {
    font-size: 1.4rem;
  }

  #footer-contact,
  #footer-contact-email {
    font-size: 1.2rem;
  }

  #main-logo {
    width: 40%;
  }
}

@media (max-width: 480px) {
  #text-header {
    font-size: 2rem;
  }

  #text-content {
    font-size: 1.2rem;
  }

  #footer-contact,
  #footer-contact-email {
    font-size: 1rem;
  }

  #main-logo {
    width: 60%;
    top: 20%;
  }

  #text-container {
    bottom: 15%;
  }
}
